<template>
  <v-main v-if="lvl == 1" class="ma-0 pa-0">
    <v-container fluid class="text-right ma-0 pa-0">
      <v-row dense class="ma-0 pa-0">
        <v-col>
          <v-dialog v-model="dialog" max-width="400px">
            <template v-slot:activator="{ on }">
              <v-btn class="mx-3 ma-0" color="white" fab v-on="on" elevation=0><v-icon color="#ab1d66" x-large>add</v-icon></v-btn>
            </template>
            <v-card class="pt-2">
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      :items="categories"
                      item-text="name"
                      item-value="category"
                      v-model="editedItem.category"
                      class="right-input"
                      label="카테고리" />
                  </v-col>
                  <v-col cols="12">
                    <v-select :items="grades" item-text="name" item-value="grade" v-model="editedItem.grade" class="right-input" label="등급" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.name" class="right-input" label="보충제명" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.amount" class="right-input" label="섭취량" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.comment" class="right-input" label="코멘트" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.target" class="right-input" label="대상" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.usage" class="right-input" label="섭취방법" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" class="white--text" @click="close">취소</v-btn>
                <v-btn color="blue darken-1" class="white--text" @click="save">저장</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog2" max-width="400px">
            <v-card>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field v-model="editedLink.name" class="right-input" label="보충제명" />
                  </v-col>
                  <v-col cols="12">
                    <v-select :items="types" item-text="name" item-value="shop" v-model="editedLink.type" class="right-input" label="종류" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedLink.link" class="right-input" label="링크" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" class="white--text" small @click="close_link">취소</v-btn>
                <v-btn color="blue darken-1" class="white--text" small @click="save_link">저장</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-col>
      </v-row>

      <v-card class="pa-3 ma-2 text-left" flat>
        <v-row dense>
          <v-col cols="3" >
            <div class="headline font-weight-bold pa-1 pb-0">보충제</div>
            <div class="subtitle caption pa-1">
              <div class="desc_title">
                보충제 관리자 화면
              </div>
            </div>
            <v-row dense>
              <v-col cols="12">
                카테고리 관리<span class="caption">(드래그&드롭으로 순서 변경)</span>
                <v-row dense>
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      dense
                      v-model="new_category"
                      placeholder="새 카테고리 입력"
                      single-line
                      hide-details />
                  </v-col>
                  <v-col cols="4">
                    <v-btn block color="primary" @click="category_add">
                      추가
                    </v-btn>
                  </v-col>
                </v-row>

                <draggable
                  :list="categories"
                  class="list-group"
                  ghost-class="ghost"
                  @start="dragging = true"
                  @end="category_move"
                >
                  <v-card
                    outlined
                    class="list-group-item mb-1 elevation-0"
                    v-for="(each, idx) in categories"
                    :key="each._id"
                  >
                    <v-row dense>
                      <v-col cols="8" class="pt-2 pl-5">
                        <v-text-field
                          v-model="each.name"
                          single-line
                          class="ma-0 pa-0"
                          :disabled="each.order == -1"
                          @change="category_change_name(each._id, each.name)"
                          />
                      </v-col>
                      <v-col cols="4" align=right>
                        <v-btn
                          text
                          color="red"
                          @click="category_del(idx, each._id)"
                          :disabled="!!each.children || each.order == -1">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </draggable>

              </v-col>
            </v-row>

          </v-col>
          <v-col cols="9" >

            <v-container fluid v-if="loading">
              <v-row dense>
                <v-col cols="12">
                  <v-row class="align-center justify-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>

            <v-tabs v-model="supplement_tab">
              <v-tab v-for="(category) in categories" :key="category.category">
                {{ category.name }}
              </v-tab>

              <v-tabs-items touchless v-model="supplement_tab">
                <v-tab-item
                  v-for="(category) in categories"
                  :key="category.category"
                  :transition="false"
                  :reverse-transition="false">
                  <draggable
                    :list="list"
                    class="col-12"
                    ghost-class="ghost"
                    @start="dragging = true"
                    @end="move"
                    style="display:inherit;" >

                    <div v-for="(each, idx) in list" :key="each._id">

                      <v-card v-if="each.category == category.category" outlined class="body-2 mb-2 pa-2 pt-1 pb-1 caption">
                        <v-row dense >
                          <v-col cols="12">
                            <v-row dense>
                              <v-col cols="10" class="subtitle-2 font-weight-bold ma-0 pa-0" >
                                <v-icon v-if="each.grade == 1" color="green">looks_one</v-icon>
                                <v-icon v-if="each.grade == 2" color="blue">looks_two</v-icon>
                                <v-icon v-if="each.grade == 3" color="orange">local_hospital</v-icon>
                                {{ each.name }}
                                <v-btn icon color="black" small @click="edit_supplement(idx, each)">
                                  <v-icon color="blue" small>edit</v-icon>
                                </v-btn>
                                <v-btn icon color="black" small @click="del_supplement(each._id)">
                                  <v-icon color="red" small>mdi-close</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" class="ma-0 pa-0">
                                섭취량 : {{ each.amount }}
                              </v-col>
                              <v-col cols="12" class="ma-0 pa-0">
                                코멘트 : {{ each.comment }}
                              </v-col>
                              <v-col cols="12" class="ma-0 pa-0">
                                대상 : {{ each.target }}
                              </v-col>
                              <v-col cols="12" class="ma-0 pa-0">
                                섭취방법 : {{ each.usage }}
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="12">
                                상품링크
                                <v-btn class="ma-0" color="white" fab elevation=0 xSmall @click="open_dialog_link(each._id)">
                                  <v-icon color="#ab1d66" small>add</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col md="3" cols="6" v-for="(link, i) in each.link" :key="i" class=" ma-0 pa-0">

                                <v-card flat class="caption pl-2">
                                  <v-row no-gutters>
                                    <v-col cols="8" class=" ma-0 pa-0">
                                      <v-btn icon color="black" xSmall @click="edit_link(each._id, i, link)">
                                        <v-icon color="blue" xSmall>edit</v-icon>
                                      </v-btn>
                                      <v-btn icon color="black" xSmall @click="del_link(each._id, i)">
                                        <v-icon color="red" xSmall>mdi-close</v-icon>
                                      </v-btn>

                                      <div v-if="link.type == 1">
                                        <img :src="imgs[link.type-1]" width=80 />
                                      </div>
                                      <div v-if="link.type == 2">
                                        <img :src="imgs[link.type-1]" width=80 />
                                      </div>
                                      <div v-if="link.type == 3">
                                        <img :src="imgs[link.type-1]" width=80 />
                                      </div>
                                    </v-col>
                                    <v-col cols="12">
                                      {{ link.name }}
                                    </v-col>
                                    <v-col cols="12">
                                      <a :href="link.link" target="_blank">{{ link.link }}</a>
                                    </v-col>
                                  </v-row>
                                </v-card>

                              </v-col>

                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>

                  </draggable>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>

          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

import draggable from 'vuedraggable';

let id = 0;
let order = 0;
let order2 = {};

export default {
  components: {
    draggable,
  },
  props: {
    msg: String
  },
  data: () => {
    return {
      new_category: "",
      supplement_tab: 0,
      dragging: false,
      loading: true,
      dialog: false,
      dialog2: false,
      list: [],
      supp_id_selected: '',
      grades: [
        { name: 'A급', grade: 1 },
        { name: 'B급', grade: 2 },
        { name: '번외성분', grade: 3 },
      ],
      types: [
        { name: 'iHerb', shop: 1 },
        { name: '쿠팡', shop: 2 },
        { name: 'Myprotein', shop: 3 },
      ],
      imgs: [
        require('@/assets/iherb.png'),
        require('@/assets/coupang.png'),
        require('@/assets/myprotein.png')
      ],
      categories: [],
      categories_default: [
        { name: '초보자', category: "0", order: -1 },
        { name: '숙련자', category: "1", order: -1 },
      ],
      editedIndex: -1,
      editedLinkIndex: -1,
      editedItem: {
        category: 0,
        grade: 0,
        name: '',
        amount: '',
        comment: '',
        target: '',
        usage: '',
      },
      defaultItem: {
        category: 0,
        grade: 0,
        name: '',
        amount: '',
        comment: '',
        target: '',
        usage: '',
      },
      linkItem: {
        name: '',
        type: 0,
        link: '',
      },
      editedLink: {
        name: '',
        type: 0,
        link: '',
      },
      linkdefaultItem: {
        name: '',
        type: 0,
        link: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",
      mobile: "mobile",

    }),
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  created: async function() {
    this.loading = true;
  },
  mounted: async function() {
    await this.reload_info();
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialog2 (val) {
      val || this.close_link()
    },
  },
  methods: {
    reload_info: async function() {

      this.categories = this.categories_default;

      if ( firebase.auth().currentUser ) {
        // 이전 정보가 있는지 확인
        const id_token = await firebase.auth().currentUser.getIdToken();
        const supplements_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        if (supplements_.data.length>0) {
          this.list = supplements_.data;
          order = supplements_.data.length;
        }


        const categories_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/category/', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        for ( const each of categories_.data ) {
          this.categories.push({
            name: each.name,
            order: each.order,
            _id: each._id,
            category: each._id,
            children: 0,
          });

          order2[each._id] = 0;

          id++;
        }


        this.loading = false;
      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }
    },
    close: function () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    close_link: function () {
      this.dialog2 = false
      setTimeout(() => {
        this.editedLink = Object.assign({}, this.linkdefaultItem)
        this.editedLinkIndex = -1
      }, 300)
    },
    open_dialog_link: async function(_id) {

      this.dialog2 = true;

      this.supp_id_selected = _id;

    },
    save: async function () {

      if (this.editedIndex > -1) {
        Object.assign(this.list[this.editedIndex], this.editedItem);
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.put(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/', {
          email: this.user.data.email,
          _id: this.editedItem._id,
          data: this.editedItem,
        }, {
          headers: {'id_token': id_token},
        });
      } else {
        this.list.push(this.editedItem)
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/', {
          email: this.user.data.email,
          supplement: this.editedItem,
        }, {
          headers: {'id_token': id_token},
        });

        order++;

      }

      this.close()
    },
    save_link: async function() {

      let tmp_ = [];

      if (this.editedLinkIndex > -1) {

        for ( let each of this.list ) {
          if ( each._id == this.supp_id_selected ) {
            each.link[this.editedLinkIndex] = this.editedLink;
            tmp_ = each.link;
          }
        }

      } else {

        for ( let each of this.list ) {
          if ( each._id == this.supp_id_selected ) {
            if ( each.link === undefined ) {
              each.link = [];
            }
            each.link.push(this.editedLink);
            tmp_ = each.link;
          }
        }
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.put(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/link/', {
        email: this.user.data.email,
        _id: this.supp_id_selected,
        link: tmp_,
      }, {
        headers: {'id_token': id_token},
      });

      this.linkItem = this.linkdefaultItem;
      this.supp_id_selected = '';

      this.close_link();
    },
    del_supplement: async function(_id) {

      let index = 0;
      for ( let each of this.list ) {
        if ( each._id == _id ) {
          break;
        }
        index++;
      }

      confirm('삭제하시겠습니까?') && this.list.splice(index, 1);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/', {
          data: {
            email: this.user.data.email,
            supp_id: _id,
          },
          headers: {'id_token': id_token},
      });

      this.move();
    },
    edit_supplement: async function(idx, item) {

      this.editedIndex = idx;

      this.editedItem = item;
      //this.editedItem.category = item.category;

      console.log(this.editedItem.category);
      console.log(this.categories);

      this.dialog = true;
    },
    del_link: async function(_id, link_idx) {

      let tmp_ = [];
      for ( let each of this.list ) {
        if ( each._id == _id ) {
          tmp_ = each.link;
        }
      }

      confirm('삭제하시겠습니까?') && tmp_.splice(link_idx, 1);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.put(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/link/', {
        email: this.user.data.email,
        _id: _id,
        link: tmp_,
      }, {
        headers: {'id_token': id_token},
      });
    },
    edit_link: async function(_id, idx, item) {
      this.editedLinkIndex = idx;

      this.editedLink = item;

      this.dialog2 = true;

      this.supp_id_selected = _id;
    },
    move: async function(e) {
      // const from_ = this.list[e.oldIndex];
      // const to_ = this.list[e.newIndex];

      // console.log(from_, to_);

      let data = [];

      let i = 0;
      for ( let each of this.list ) {
        let t_ = {};
        t_['_id'] = each._id;
        t_['order'] = i++;
        data.push(t_);
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.put(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/order/', {
        email: this.user.data.email,
        data: data,
      }, {
        headers: {'id_token': id_token},
      });

      //await this.reload_info();
    },

    category_add: async function() {
      if ( this.new_category == "") {
        alert("카테고리 명을 입력하세요.");
        return;
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      const r = await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/category', {
        email: this.user.data.email,
        name: this.new_category,
        order: id,
      }, {
        headers: {'id_token': id_token},
      });

      this.categories.push({ name: this.new_category, id: id, _id: r.data._id });

      id++;

      order2[r.data._id] = 0;

      this.new_category = "";
      await this.category_order();
    },

    category_del: async function(idx, _id) {
      if ( !confirm('삭제하시겠습니까?') ) return;

      this.categories.splice(idx, 1);

      for ( let each of this.categories ) {
        if ( each.id == -1 ) continue;
        if ( each.id > idx ) {
          each.id = --each.id;
        }
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/category', {
          data: {
            email: this.user.data.email,
            category_id: _id,
          },
          headers: {'id_token': id_token},
      });

      id--;

      await this.category_order();

      // await this.reload_link();
    },

    category_move: async function(e) {
      await this.category_order();
    },

    category_order: async function(e) {

      let data = [];

      let i = 0;
      for ( let each of this.categories ) {
        if ( each.id == -1 ) continue;
        let t_ = {};
        t_['_id'] = each._id;
        t_['order'] = i++;
        data.push(t_);
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.put(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/category/order/', {
        email: this.user.data.email,
        data: data,
      }, {
        headers: {'id_token': id_token},
      });

    },

    category_change_name: async function(_id, name) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.put(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/supplement/category/name', {
        email: this.user.data.email,
        _id: _id,
        name: name,
      }, {
        headers: {'id_token': id_token},
      });

      //await this.reload_link();
    },

    // deleteItem: async function (item) {
    //   const index = this.list.indexOf(item)

    //   const del_id = this.list[index]._id;

    //   confirm('삭제하시겠습니까?') && this.list.splice(index, 1);

    //   const id_token = await firebase.auth().currentUser.getIdToken(true);
    //   await axios.delete(
    //     api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/', {
    //       data: {
    //         email: this.user.data.email,
    //         chick_id: del_id,
    //       },
    //       headers: {'id_token': id_token},
    //   });
    // },

  }
}
</script>

<style scoped>


.desc {
  margin-top:10px;
}

.headline {
  font-size: 1.3rem !important;
}

.desc_title {
  font-size:1.1em;
}

</style>
